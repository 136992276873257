


























































































import Vue from 'vue'
import Nav from '@/components/Nav.vue'
import RequisitionForm from '@/components/RequisitionForm.vue'
import RequisitionItems from '@/views/requisitionItems/List.vue'
import DocumentsList from '@/components/DocumentsList.vue'
import DocumentsUpload from '@/components/DocumentsUpload.vue'
import { ApiResponse, Project, Document, RequisitionItem, Contact } from '@/interfaces'
import { mapState } from 'vuex'

interface Badge {
  color: string
  value: string
}

interface TabObject {
  _props: Record<string, string>
}

export default Vue.extend({
  components: { Nav, RequisitionForm, RequisitionItems, DocumentsList, DocumentsUpload },
  data() {
    return {
      displayAlert: false,
      project: {
        requisition: {
          id: null,
        },
      } as Project,
      requisitionId: null as number | null,
      busy: false,
      customColors: [
        { color: '#48C989', percentage: 25 },
        { color: '#F7AD57', percentage: 50 },
        { color: '#E8584F', percentage: 75 },
        { color: '#263238', percentage: 100 },
      ],
      saveRequisitionTrigger: false,
      documents: [] as Document[],
      docToUpdate: null as null | Document,
      activeTab: 'items',
      projectRequerant: {} as Contact | null,
      projectDebiteur: {} as Contact | null,
      projectId: null as null | string,
      uploadCompleted: 0,
      uploadStatus: null as string | null,
      showProgressModal: false,
      massSending: false,
      sendReport: [],
      axiosController: new AbortController(),
      addons: 0,
    }
  },
  computed: {
    ...mapState(['user']),
  },
  mounted() {
    this.initProject()

    if (this.$route.query.tab && this.$route.query.tab !== null) {
      this.activeTab = this.$route.query.tab as string
    }
    if (this.user) {
      this.addons = this.user.client?.addons ?? 0
    }
  },
  methods: {
    initProject() {
      if (!this.projectId || this.projectId === null) {
        this.$api.get(`/requisition/${this.$route.params.requisitionId}/project`).then((response) => {
          const apiResponse = response.data as ApiResponse

          this.projectId = apiResponse.data.id

          this.refreshProject()
        })
      }
    },
    refreshProject() {
      if (this.projectId) {
        this.busy = true
        this.displayAlert = false

        this.$api
          .get(`/project/${this.projectId}`)
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            this.project = apiResponse.data
            this.requisitionId = this.project.requisition?.id ?? null
            //this.getDocuments()
            this.projectRequerant = this.project.requisition?.requerant ?? null
            if (this.projectRequerant?.id !== this.project.requisition?.debiteur?.id) {
              this.projectDebiteur = this.project.requisition?.debiteur ?? null
            }
          })
          .catch(() => {
            this.displayAlert = true
          })
          .finally(() => {
            this.busy = false
          })
      }
    },
    requisitionCreated(project: Project) {
      if (project.id) {
        this.$router.push({
          name: 'requisition_edit',
          params: {
            projectId: project.id.toString(),
            requisitionId: project.requisition?.id ? project.requisition.id.toString() : '',
          },
        })
      }
    },
    updateProject(project: Project) {
      this.project = project
      this.projectRequerant = this.project.requisition?.requerant ?? null
      if (this.projectRequerant?.id !== this.project.requisition?.debiteur?.id) {
        this.projectDebiteur = this.project.requisition?.debiteur ?? null
      }
    },
    updateRequisitionItems(items: RequisitionItem[]) {
      ; (this.$refs.requisitionItems as InstanceType<typeof RequisitionItems>).updateItemSignedDocument(items)
    },
    getNumItems(project: Project): string {
      if (project.requisition) {
        const num = project.requisition.numItems ? `(${project.requisition.numItems})` : ''
        return `Lots ${num}`
      } else {
        return 'Lots'
      }
    },
    getDebiteurFormname(project: Project) {
      let debiteur = project.requisition?.debiteur?.formName ?? ''
      if (debiteur != '') {
        return '- ' + debiteur
      } else {
        return ''
      }
    },
    getProjectType(project: Project) {
      switch (project.structure?.type) {
        case 'volontaire':
          return 'is-volontary'

        case 'judiciaire':
          return 'is-judiciary'

        default:
          return 'is-unknown'
      }
    },
    deleteProject(project: Project) {
      this.$confirm(
        'Êtes-vous sûr(e) de vouloir supprimer cette réquisition ? Attention, cette opération est irréversible.',
        'Confirmation',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }
      ).then(() => {
        this.$api
          .delete(`/project/${project.id}`)
          .then(() => {
            this.$notify({
              type: 'success',
              title: 'Succès',
              message: 'Opération réalisée avec succès !',
            })

            this.$router.push({ name: 'requisitions' })
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: 'Une erreur est survenue. Merci de reéssayer plus tard.',
            })
          })
      })
    },
    getStatusBadge(project: Project): Badge {
      switch (project.status) {
        case 'ouvert':
          return {
            color: '#EFE6FD',
            value: 'Ouvert',
          }

        case 'prospect':
          return {
            color: '#EFE6FD',
            value: 'Prospect',
          }
        case 'rendez-vous_pris':
          return {
            color: '#EFE6FD',
            value: 'Rendez-vous pris',
          }
        case 'en_attente':
          return {
            color: '#EFE6FD',
            value: 'En attente',
          }
        case 'en_cours':
          return {
            color: '#EFE6FD',
            value: 'En cours',
          }
        case 'inventaire':
          return {
            color: '#EFE6FD',
            value: 'Inventaire',
          }
        case 'preparation_vente':
          return {
            color: '#EFE6FD',
            value: 'Préparation de vente',
          }
        case 'vente_en_cours':
          return {
            color: '#EFE6FD',
            value: 'Vente en cours',
          }
        case 'difficulte':
          return {
            color: '#EFE6FD',
            value: 'Difficulté',
          }
        case '2eme_relance':
          return {
            color: '#EFE6FD',
            value: 'Deuxième relance',
          }
        case 'mise_en_demeure':
          return {
            color: '#EFE6FD',
            value: 'Mise en demeure',
          }
        case 'regle':
          return {
            color: '#EFE6FD',
            value: 'Reglé',
          }
        case 'clos':
          return {
            color: '#EFE6FD',
            value: 'Clôturé',
          }
        case 'annule':
          return {
            color: '#EFE6FD',
            value: 'Annulé',
          }

        default:
          return {
            color: '#EFE6FD',
            value: '-',
          }
      }
    },
    changeTab(tab: TabObject) {
      if (!this.busy) {
        this.activeTab = tab._props.name ?? 'infos'
        // Here we use pushState as using Vue's router would trigger a page reload
        history.pushState(null, '', `${this.$route.path}?tab=${this.activeTab}`)

        this.triggerSave()

        // plus simple : traiter dans le parent le test de l'active tab
        // plutot que le de passer a l'enfant pour qu'il appelle une fonction du
        // parent pour charger la liste des docs
        switch (this.activeTab) {
          case 'documents':
            this.getDocuments()
            break
        }
      }
    },
    triggerSave() {
      this.saveRequisitionTrigger = !this.saveRequisitionTrigger

      return true
    },
    goBack() {
      this.triggerSave()
      this.$router.back()
    },
    getDocuments() {
      this.$api(`/project/${this.projectId}/documents`).then((response) => {
        const apiResponse = response.data as ApiResponse

        this.documents = apiResponse.data
      })
    },
    refreshDocuments(documents: Document[]) {
      // Les appels sur chaque doc ne sont pas sensé renvoyer une liste mais le doc mis à jour
      // l'appel de refresh s'occupe donc de recharger la liste si besoin.
      //this.documents = documents
      this.getDocuments()
      this.docToUpdate = null
    },
    updateDoc(document: Document) {
      this.docToUpdate = document
        ; (this.$refs.uploadDoc as InstanceType<typeof DocumentsUpload>).addFile()
    },
    exportAsCSV(project: Project) {
      this.$api.get(`/requisition/${project.requisition?.id}/items`).then((response) => {
        const apiResponse = response.data as ApiResponse
        const items = apiResponse.data

        let csvArray = [] as string[][]

        csvArray.push([
          '"ID"',
          '"Ref. stock"',
          '"N° du lot"',
          '"N° du sous-lot"',
          '"Description"',
          '"Estimation basse"',
          '"Estimation haute"',
          '"PR Brut"',
          '"PR Net"',
          '"Mise à prix"',
        ])

        if (items.length > 0) {
          items.forEach((item: RequisitionItem) => {
            csvArray.push([
              `"${item.id}"`,
              `"${item.stockId ?? ''}"`,
              `"${item.itemNumber ?? ''}"`,
              `"${item.itemSubNumber ?? ''}"`,
              `"${item.description ? item.description.replace(/"/g, '""') : ''}"`,
              `"${item.estimationLow ?? ''}"`,
              `"${item.estimationHigh ?? ''}"`,
              `"${item.reservePrice ?? ''}"`,
              `"${item.reserveNetPrice ?? ''}"`,
              `"${item.startingPrice ?? ''}"`,
            ])
          })
        }

        let csvContent = 'data:text/csv;charset=utf-8,\uFEFF'

        csvArray.forEach(function (rowArray) {
          let row = rowArray.join(';')
          csvContent += row + '\r\n'
        })

        let encodedURI = encodeURI(csvContent)

        let downloadLink = document.createElement('a')
        downloadLink.href = encodedURI
        downloadLink.download = 'export-requisition.csv'

        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
      })
    },
    addFile() {
      document.getElementById('inputRequisitionImport')?.click()
    },
    addFileLaposte() {
      document.getElementById('inputFileLaposte')?.click()
    },
    updateInputFile(event: Event) {
      const inputFiles = (event.target as HTMLInputElement).files
      if (!inputFiles) {
        this.$notify({
          type: 'error',
          title: 'Erreur',
          message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
        })
        return
      }
      this.importRequisition(inputFiles[0])
    },
    updateInputFileLaposte(event: Event) {
      const input = event.target as HTMLInputElement

      if (input.files && input.files.length) {
        // Checking if upload would exceed
        const fileSize = input.files[0].size

        // Multiply diskUsage and diskQuota by 1000 to get the value in Octets
        /*
        if (this.diskUsage * 1000 + fileSize > this.diskQuota * 1000) {
          this.$notify({
            title: 'Erreur',
            message: "Le fichier que vous essayez d'envoyer dépasse la limite du quota autorisée.",
            type: 'error',
          })
          this.abortMassUpload()
          return
        }
        */

        // Upload the zip file
        this.uploadCompleted = 0
        this.uploadStatus = null
        this.showProgressModal = true

        let formData = new FormData()

        formData.append('file', input.files[0])

        this.axiosController = new AbortController()

        let config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent: ProgressEvent) => {
            // Refusing the upload if it's higher than the limit specified in config
            const uploadLimit = parseInt(process.env.VUE_APP_UPLOAD_LIMIT ?? '')
            if (!isNaN(uploadLimit) && progressEvent.total > uploadLimit * 1000000) {
              this.$notify({
                title: 'Erreur',
                message: "Le taille du fichier que vous essayez d'envoyer est trop élevée.",
                type: 'error',
              })
            } else {
              this.uploadCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            }
          },
          signal: this.axiosController.signal,
        }

        this.busy = true

        this.$api
          .post(`/project/${this.$route.params.projectId}/requisition/${this.$route.params.requisitionId}/importlaposte`, formData, config)
          .then((response) => {
            const apiResponse = response.data as ApiResponse
            this.uploadStatus = 'success'

            this.project = apiResponse.data
            this.$notify({
              type: 'success',
              title: 'Succès',
              message: 'Import réalisé avec succès !',
            })

            // Emiting event that should trigger a list refresh
            //this.$emit('updateItems')
          })
          .catch(() => {
            this.uploadStatus = 'exception'
          })
          .finally(() => {
            this.busy = false
          })
      }
    },
    importRequisition(file: File) {
      if (!file) return
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.addEventListener('load', () => {
        if (reader.result) {
          const data = {
            filename: file.name,
            data: reader.result.toString().split(',')[1],
          }

          this.busy = true

          this.$api
            .post(
              `/project/${this.$route.params.projectId}/requisition/${this.$route.params.requisitionId}/import`,
              data
            )
            .then((response) => {
              const apiResponse = response.data as ApiResponse

              this.project = apiResponse.data
              this.$notify({
                type: 'success',
                title: 'Succès',
                message: 'Import réalisé avec succès !',
              })
            })
            .catch((error) => {
              if (error.response?.status === 413) {
                this.$notify({
                  type: 'error',
                  title: 'Erreur',
                  message: 'Erreur : le fichier que vous souhaitez téléverser est trop lourd.',
                })
              } else {
                this.$notify({
                  type: 'error',
                  title: 'Erreur',
                  message:
                    error.response.data.message ??
                    'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
                })
              }
            })
            .finally(() => {
              this.busy = false
            })
        }
      })
    },
    exportPhotos(project: Project) {
      const requisitionId = project.requisition?.id
      this.$api
        .post(`/requisition/${requisitionId}/export-photos`)
        .then(() => {
          const documentUrl = `${process.env.VUE_APP_API_URL}/document/requisition/${requisitionId}/${this.user.client.id}/download`
          window.open(documentUrl, '_blank')
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Erreur',
            message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
          })
        })
    },
    getStructureColor(project: Project) {
      return project.structure?.color
    },
  },
})
